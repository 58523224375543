body {
  margin: 0;
  vertical-align: middle;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.topBar{
  height: auto;
  display: flex;
  justify-content: flex-end;
}
.facebookFollow{
 height:20px;
 padding: 5px;

}
.navBar {
  position: sticky;
  top: 0%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: #E9EBEE;
  padding: 1vw;
  z-index: 1000;
}

.navBarShrunk {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.homePage{
  background-image: url("./Images/browpro_home.jpg");
  width:100%;
  background-size: cover;
  background-repeat: no-repeat;
  height:100vh;
}
.navContainer{
  display: flex;
  flex-direction: row;
  font-size: 1.3vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  
  
}

.navItem {
  width: auto;
  color: #101010;
  text-align: center;
  text-transform: capitalize;
  padding: 15px;
  cursor: pointer;
  
}

@media screen and (max-width: 800px) {
  .navBar{
    margin-top: 0;
    flex-direction: column;
    z-index: 100;
    
  }
  .navContainer{
    font-size: 3vw;
  }
  .logo, .logoBig, .logoSmall {
    font-size: 3vw !important;
    display: inline-flex;
  }
  .missionText{
    font-size: 1rem !important;
    padding: 1.5vw !important;
  }
  .homePage{
    height: 30vh;
  }
  .welcome{
    font-size: 1.5rem !important;
    letter-spacing: 0.5rem !important;
  }
  .appointmentBtn{
    align-self: center;
    padding: 0;
    
  }
  .pageTitle {
    font-size: 2rem !important;
  }
  .aboutUs .pageContent {
    font-size: 1rem !important;
    letter-spacing: 2px;
    font-stretch: extra-expanded;
    font-weight: 100;
    justify-content: center;
  }
  .serviceItem {
    flex: none !important;
    max-width: 300px !important;
    display: block;
    margin: 0.5rem;
    padding-bottom: 1rem;
    
  
  }
  .aboutImages{
    
    width: 100vw !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    overflow: scroll;
  
  }
  .aboutImages::-webkit-scrollbar {
    display: none;
  }
  

  .aboutImages .aboutImage {
    height: 25vh !important;
    padding: 5px;
  }

  .contactUs .pageContent {
    display: flex;
    flex-direction: column;
    width: 95vw;
    

  }

  .map{
    flex: auto !important;
    height: 300px !important;
    width: 100% !important;
    padding-top: 15px !important;
    padding-left: 0px !important;
  }
}


.navItem:hover , .active{
 color: gray;
}

.logo {
  color: #101010;
  font-weight:bold;
  text-align: left;
  font-family: Source Sans Pro-regular;
  text-align: center;

}

.logoBig {
  font-size: 1.5vw;}
.logoSmall {
  font-size: 1vw;
}

.missionText{
  padding: 0vh 0vw 2vw 1.5vw ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 3.5rem;
    font-stretch: extra-expanded;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.5vw;
}

.welcome {
  font-size: 3rem;
    letter-spacing: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 1.5vw;
}

.appointmentBtn {
  padding: 15px; 
  background-color: #e58f8f;
  border: none;
  width: fit-content;
  border-radius: 10.5px;
  color: #fff;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-left: 1.5rem;
  text-decoration: auto;
}

.homePageContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page {
  background: rgb(209,213,222);
  background: linear-gradient(90deg, #dfe0e5 0%, #e9e9ed 36%, #fff 78%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  text-align: center;
}

.pageTitle {
    font-size: 3rem;
    font-stretch: extra-expanded;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.5vw;
}

.serviceItem {
  flex: 1 1 0px;
  max-width: 350px !important;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  padding-bottom: 1rem;

}

.serviceItem:nth-of-type(odd){
  background-color: #e9ebee;
  border: 1px solid white;
}
.serviceItem:nth-of-type(even){
  background-color: white;
  border: 1px solid #e9ebee;
}

.image {
  width: 100%;
  max-height: 185px;
}



.aboutUs{
  
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1000px;
  align-self: center;
  max-width: 1200px;
  
}

.aboutUs .pageContent {
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-stretch: extra-expanded;
  font-weight: 100;
  justify-content: center;
  position: relative;
}
.aboutImages{
  display: flex;
  width: 100%;
  flex: 1 1;
}

.aboutImages .aboutImage {
  max-height: 50vh;
  padding: 10px;
  overflow: hidden;
}


.services .pageContent {
  justify-content: center;
  width: 98vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}



.contactUs .pageContent {
  display: flex;
  width: 95vw;
  padding: 0.5rem;
  margin: 0.5rem;
  font-weight: 100;
  font-size: 2rem;
  text-align: center;
  background-color: white;

  
}

.description {
  letter-spacing: 1.5px;
  font-weight: 100;
  padding: 5px;
}

.priceDuration{
  padding-top: 15px;
    font-size: medium;
    font-style: italic;
    font-weight: lighter;
}

.name {
  font-size: 1.5rem;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.openingHoursContainer{
  padding-top: 1.5rem;
  font-size: 1.5rem
}

.openingHours{
  text-transform: uppercase;
  font-weight: 500;
}

.map {
  flex: 1;
  padding-left: 5rem;
  height:90vh;
  
}

.google-map {
  width: 100%;
  height: 100%;
}
.pin {
  display: flex;
  
  align-items: center;
  width: 180px;
  color: var(--main-blue);
  
}

.pin-icon {
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 50px ;
  
}

.pin-text {
  transform: translate(-50%, -50%);
  padding-left :250px;
  width:200px;
  position: absolute;
  font-size: 15px;
}